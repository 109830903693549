import React from 'react';

import '../../styles/Resources/resources.css';
const Tab = ({SecondaryTitle}) =>{
    return(
        <div className="resources-secondary-title">
            <h2>{SecondaryTitle}</h2>
        </div>
    );
}


export default Tab;